import React, { useContext, useMemo, useState } from "react";
import { RedirectException } from "found";
import CurrentUserContext from "../../CurrentUserContext";
import LoaderBarContext from "../../ui/useLoaderBar";
import http, { HTTPError } from "../../http";
import styled from "styled-components";

import PageHeader, { Cntnr, Title } from "../../ui/PageHeader";
import Button from "../../ui/Button";
import Label from "../../ui/Label";
import Input, { InputIcon } from '../../ui/Input';
import FormGroup, { FormGroupRow } from '../../ui/FormGroup';
import Alert from "../../ui/Alert";
import Steps from "../../ui/Steps";
import theme from "../../ui/theme";
import InputPassword from '../../ui/InputPassword';
import KeyIcon from '@mui/icons-material/VpnKey';
import { grey } from '@mui/material/colors';


const PswrdDoItem = styled.li`
  font-weight: ${props => props.itemValid ? "normal" : "bold"};
  color: ${props => props.itemValid ? theme.green : theme.text};
`;

const Grey = styled.span`
  color: #AAA;
`;

function PasswordChange({ router }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [form, setForm] = useState({
    currentPassword: "",
    newPassword: "",
    newConfirmation: ""
  });
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasSpecialCaracters, setHasSpecialCaracters] = useState(false);
  const [hasDigit, setHasDigit] = useState(false);
  const [hasntUid, setHasntUid] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleFormChange = field => {
    return ev => {
      const input = ev.target.value;

      if (field === 'newPassword') {
        setHasUppercase(input.toLowerCase() !== input);
        setHasLowercase(input.toUpperCase() !== input);
        setHasSpecialCaracters(/[!@#$%^&*(),.?":{}|<>]/.test(input));
        setHasDigit(/\d/.test(input));
        setHasntUid(!input.includes(currentUser.uid));
      }

      setForm({
        ...form,
        [field]: input
      });
    };
  };

  const handleFormSubmit = async evt => {
    evt.preventDefault();
    if (loaderBarState === true) {
      return;
    };
    if (form.newPassword !== form.newConfirmation) {
      setAlert({
        kind: 'danger',
        content: 'Le nouveau mot de passe et sa confirmation ne correspondent pas.',
      });
      return;
    };
    setLoaderBar(true);
    try {
      const payload = {
        old_password: form.currentPassword,
        new_password: form.newPassword
      };
      await http.post(
        `user/${encodeURIComponent(currentUser.id)}/password_change`,
        { json: payload }
      );
      setAlert({
        kind: "success",
        content: "Votre mot de passe a bien été modifié."
      });
      setForm({
        currentPassword: "",
        newPassword: "",
        newConfirmation: ""
      });
      setHasUppercase(false);
      setHasLowercase(false);
      setHasDigit(false);
      setHasntUid(false);
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 422) {
        const payload = await e.response.json();
        setAlert({
          kind: "danger",
          content: (
            <ul>
              {payload.errors.map(err => (
                <li>{err}</li>
              ))}
            </ul>
          )
        });
      } else if (e instanceof RedirectException) {
        router.push(e.location);
      } else {
        setAlert({
          kind: "danger",
          content: "Une erreur inattendue est survenue."
        });
        console.error(e);
      }
    } finally {
      setLoaderBar(false);
    }
  };

  const disableSubmit = useMemo(() => {
    if (loaderBarState) return true;
    return !(
      form.newPassword.length >= 12
      && form.newPassword === form.newConfirmation
      && hasUppercase === true
      && hasLowercase === true
      && hasSpecialCaracters === true
      && hasDigit === true
    );
  }, [ loaderBarState, form, hasUppercase, hasLowercase, hasSpecialCaracters, hasDigit ]);

  return (
    <>
      <PageHeader>
        <Title>Modifier mon mot de passe</Title>
      </PageHeader>

      <Cntnr>
        {alert && <Alert kind={alert.kind}>{alert.content}</Alert>}
        <form onSubmit={handleFormSubmit} style={{display: 'flex', flexDirection: 'column', maxWidth: '500px'}}>
          <Steps step={1} />
          <FormGroup>
            <Label htmlFor="passchange-current-input">Mot de passe actuel (Requis)</Label>
            <FormGroupRow>
              <InputPassword
                id="passchange-current-input"
                value={form.currentPassword}
                callback={handleFormChange("currentPassword")}
              />
            </FormGroupRow>
          </FormGroup>
          <Steps step={2} />
          <p style={{marginBottom: 0}}>
            Saisissez le mot de passe que vous voulez définir pour votre compte dans les champs ci-dessous.<br/>
            Votre mot de passe doit contenir :
          </p>
          <ul style={{marginBottom: '1rem'}}>
            <PswrdDoItem itemValid={form.newPassword.length >= 12}>au moins 12 caractères</PswrdDoItem>
            <PswrdDoItem itemValid={hasUppercase}>au moins une lettre majuscule</PswrdDoItem>
            <PswrdDoItem itemValid={hasLowercase}>au moins une lettre minuscule</PswrdDoItem>
            <PswrdDoItem itemValid={hasDigit}>au moins un chiffre</PswrdDoItem>
            <PswrdDoItem itemValid={hasSpecialCaracters}>au moins un caractère spécial <Grey>{'!@#$%^&*(),.?":{}|'}</Grey></PswrdDoItem>
            <PswrdDoItem itemValid={form.newConfirmation.length > 0 && form.newPassword === form.newConfirmation}>les mots de passe doivent correspondre</PswrdDoItem>
            <PswrdDoItem itemValid={hasntUid}>ne pas contenir votre identifiant</PswrdDoItem>
          </ul>
          <FormGroup>
            <Label htmlFor="passchange-new-input">Nouveau mot de passe (Requis)</Label>
            <FormGroupRow>
              <InputPassword
                id="passchange-new-input"
                value={form.newPassword}
                callback={handleFormChange("newPassword")}
              />
            </FormGroupRow>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="passchange-confirm-input">
              Confirmez le nouveau mot de passe (Requis)
            </Label>
            <FormGroupRow>
              <InputIcon className="grey">
                <KeyIcon />
              </InputIcon>
              <Input
                id="passchange-confirm-input"
                value={form.newConfirmation}
                type="password"
                required
                onChange={handleFormChange("newConfirmation")}
              />
            </FormGroupRow>
          </FormGroup>
          <Button disabled={disableSubmit}>Valider</Button>
        </form>
      </Cntnr>
    </>
  );
}

export default PasswordChange;
